<template>
  <div>
    <vue-cropper ref="cropper" :img="img"></vue-cropper>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      img: "https://image.ydleague.com/benefit/web_icons/icon_punch_teacher_qrcode.png",
    };
  },
  mounted() {
    console.log("cropprt init");
  },
};
</script>

<style scoped></style>
