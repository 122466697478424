import Vue from "vue";
import VueRouter from "vue-router";

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
    // redirect: "/loginplat",
  },
  {
    path: "/",
    name: "home",
    component: (resolve) => require(["@/views/custom/home"], resolve),
  },
  {
    path: "/",
    name: "custom",
    component: (resolve) => require(["@/views/custom/index"], resolve),
  },
  // {
  //   path: "/",
  //   name: "index",
  //   component: (resolve) => require(["@/views/layout/index"], resolve),
  // },
  {
    path: "/movie",
    name: "movie",
    component: (resolve) => require(["@/views/movie/index"], resolve),
  },
  {
    path: "/live",
    name: "live",
    component: (resolve) => require(["@/views/live"], resolve),
  },
  {
    path: "/test",
    name: "test",
    component: (resolve) => require(["@/views/test"], resolve),
  },
  {
    path: "/testwx",
    name: "testwx",
    component: (resolve) => require(["@/views/wxwork"], resolve),
    meta: {
      title: "测试",
      keepAlive: false,
    },
  },
  {
    path: "/code/test",
    name: "codeTest",
    component: (resolve) => require(["@/views/codeTest"], resolve),
  },
  {
    path: "/mall",
    name: "mall",
    component: (resolve) => require(["@/views/mall/index"], resolve),
  },
  {
    path: "/cake",
    name: "cake",
    component: (resolve) => require(["@/views/cake/index"], resolve),
  },
  {
    path: "/perform",
    name: "perform",
    component: (resolve) => require(["@/views/perform/index"], resolve),
  },
  {
    path: "/venue",
    name: "venue",
    component: (resolve) => require(["@/views/venue/index"], resolve),
  },
  {
    path: "/gifts",
    name: "gifts",
    component: (resolve) => require(["@/views/gifts/index"], resolve),
  },
  {
    path: "/activity",
    name: "activity",
    component: (resolve) => require(["@/views/activity/index"], resolve),
  },
  {
    path: "/course",
    name: "course",
    component: (resolve) => require(["@/views/course/index"], resolve),
  },
  {
    path: "/health",
    name: "health",
    component: (resolve) => require(["@/views/health/index"], resolve),
  },
  {
    path: "/custom",
    name: "customIndex",
    component: (resolve) => require(["@/views/custom/index"], resolve),
  },
  {
    path: "/about",
    name: "customAbout",
    component: (resolve) => require(["@/views/custom/about"], resolve),
  },
  {
    path: "/products/scrm",
    name: "productsScrm",
    component: (resolve) => require(["@/views/custom/products/scrm"], resolve),
  },
  {
    path: "/products/edu",
    name: "productsEdu",
    component: (resolve) => require(["@/views/custom/products/edu"], resolve),
  },
  {
    path: "/products/mall",
    name: "productsMall",
    component: (resolve) => require(["@/views/custom/products/mall"], resolve),
  },
  {
    path: "/products/store",
    name: "productsStore",
    component: (resolve) => require(["@/views/custom/products/store"], resolve),
  },
  {
    path: "/products/beauty",
    name: "productsBeauty",
    component: (resolve) =>
      require(["@/views/custom/products/beauty"], resolve),
  },
  {
    path: "/products/hotel",
    name: "productsHotel",
    component: (resolve) => require(["@/views/custom/products/hotel"], resolve),
  },
  {
    path: "/products/food",
    name: "productsFood",
    component: (resolve) => require(["@/views/custom/products/food"], resolve),
  },
  {
    path: "/adm",
    name: "adm",
    component: (resolve) => require(["@/views/adm/passport/login"], resolve),
  },
  {
    path: "/admin",
    name: "admin",
    component: (resolve) => require(["@/views/admin/index"], resolve),
    children: [
      {
        path: "/admin/data/today",
        name: "adminDataToday",
        component: (resolve) =>
          require(["@/views/admin/data/today/index"], resolve),
      },
      {
        path: "/admin/platform/shop/list",
        name: "adminPlatformShopList",
        component: (resolve) =>
          require(["@/views/admin/platform/shop/list"], resolve),
      },
      {
        path: "/admin/platform/shop/forms",
        name: "adminPlatformShopForms",
        component: (resolve) =>
          require(["@/views/admin/platform/shop/forms"], resolve),
      },
      {
        path: "/admin/platform/shop/follow",
        name: "adminPlatformShopFollow",
        component: (resolve) =>
          require(["@/views/admin/platform/shop/follow"], resolve),
      },
      {
        path: "/admin/platform/service/clubs",
        name: "adminPlatformServiceClubs",
        component: (resolve) =>
          require(["@/views/admin/platform/service/clubs"], resolve),
      },
      {
        path: "/admin/benifit/customer/list",
        name: "adminBenifitCustomerList",
        component: (resolve) =>
          require(["@/views/admin/benifit/customer/list"], resolve),
      },
      {
        path: "/admin/user/user/list",
        name: "adminUserUserList",
        component: (resolve) =>
          require(["@/views/admin/user/user/list"], resolve),
      },
      {
        path: "/admin/saas/setting/menus",
        name: "adminSaasSettingMenus",
        component: (resolve) =>
          require(["@/views/admin/saas/setting/menus"], resolve),
      },
      {
        path: "/admin/saas/setting/category",
        name: "adminSaasSettingCategory",
        component: (resolve) =>
          require(["@/views/admin/saas/setting/category"], resolve),
      },
      {
        path: "/admin/saas/setting/switchs",
        name: "adminSaasSettingSwitchs",
        component: (resolve) =>
          require(["@/views/admin/saas/setting/switchs"], resolve),
      },
      {
        path: "/admin/system/user/sysuser",
        name: "adminSystemUserSysuser",
        component: (resolve) =>
          require(["@/views/admin/system/user/sysuser"], resolve),
      },
      {
        path: "/admin/system/user/sysrole",
        name: "adminSystemUserSysrole",
        component: (resolve) =>
          require(["@/views/admin/system/user/sysrole"], resolve),
      },
      {
        path: "/admin/system/setting/menus",
        name: "adminSystemSettingMenus",
        component: (resolve) =>
          require(["@/views/admin/system/setting/menus"], resolve),
      },
      {
        path: "/admin/platform/service/goods",
        name: "adminPlatformServiceGoods",
        component: (resolve) =>
          require(["@/views/admin/platform/service/goods"], resolve),
      },
      {
        path: "/admin/platform/service/course",
        name: "adminPlatformServiceCourse",
        component: (resolve) =>
          require(["@/views/admin/platform/service/course"], resolve),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: (resolve) =>
      require(["@/views/platform/passport/login"], resolve),
  },
  {
    path: "/register",
    name: "register",
    component: (resolve) =>
      require(["@/views/platform/passport/register"], resolve),
  },
  {
    path: "/shops",
    name: "shopList",
    component: (resolve) =>
      require(["@/views/platform/passport/shops"], resolve),
  },
  {
    path: "/user",
    name: "userInfo",
    component: (resolve) =>
      require(["@/views/platform/passport/user"], resolve),
  },
  {
    path: "/create",
    name: "shopCreate",
    component: (resolve) =>
      require(["@/views/platform/passport/create"], resolve),
  },
  {
    path: "/design",
    name: "design",
    component: (resolve) => require(["@/views/design/index"], resolve),
  },
  {
    path: "/designer",
    name: "designer",
    component: (resolve) => require(["@/views/design/designer"], resolve),
  },
  {
    path: "/fabrc",
    name: "fabrc",
    component: (resolve) => require(["@/views/design/fabrc"], resolve),
  },
  {
    path: "/community/home",
    name: "communityHome",
    component: (resolve) => require(["@/views/community/home"], resolve),
  },
  {
    path: "/community/index",
    name: "communityIndex",
    component: (resolve) => require(["@/views/community/index"], resolve),
  },
  {
    path: "/community/:communityId/article",
    name: "communityArticle",
    component: (resolve) => require(["@/views/community/article"], resolve),
  },
  {
    path: "/community/favorite",
    name: "communityFavorite",
    component: (resolve) => require(["@/views/community/favorite"], resolve),
  },
  {
    path: "/community/dynamic",
    name: "communityDynamic",
    component: (resolve) => require(["@/views/community/dynamic"], resolve),
  },
  {
    path: "/community/:communityId/files",
    name: "communityFiles",
    component: (resolve) =>
      require(["@/views/community/detail/files"], resolve),
  },
  {
    path: "/community/:communityId/excellent",
    name: "communityExcellent",
    component: (resolve) =>
      require(["@/views/community/detail/excellent"], resolve),
  },
  {
    path: "/community/:communityId/group",
    name: "communityGroup",
    component: (resolve) =>
      require(["@/views/community/detail/group"], resolve),
  },
  {
    path: "/community/admin/list",
    name: "communityAdminList",
    component: (resolve) => require(["@/views/community/admin/list"], resolve),
  },
  {
    path: "/community/admin/create",
    name: "communityAdminCreate",
    component: (resolve) =>
      require(["@/views/community/admin/create"], resolve),
  },
  {
    path: "/community/admin/:communityId",
    name: "communityAdminIndex",
    component: (resolve) => require(["@/views/community/admin/index"], resolve),
    children: [
      {
        path: "/community/admin/:communityId/dashboard",
        name: "communityAdminDashboard",
        component: (resolve) =>
          require(["@/views/community/admin/dashboard/index"], resolve),
      },
      {
        path: "/community/admin/:communityId/newer/spread",
        name: "communityAdminNewerSpread",
        component: (resolve) =>
          require(["@/views/community/admin/newer/spread"], resolve),
      },
      {
        path: "/community/admin/:communityId/newer/coupon",
        name: "communityAdminNewerCoupon",
        component: (resolve) =>
          require(["@/views/community/admin/newer/coupon"], resolve),
      },
      {
        path: "/community/admin/:communityId/newer/qrcode",
        name: "communityAdminNewerQrcode",
        component: (resolve) =>
          require(["@/views/community/admin/newer/qrcode"], resolve),
      },
      {
        path: "/community/admin/:communityId/newer/page",
        name: "communityAdminNewerPage",
        component: (resolve) =>
          require(["@/views/community/admin/newer/page"], resolve),
      },
      {
        path: "/community/admin/:communityId/active/member",
        name: "communityAdminActiveMember",
        component: (resolve) =>
          require(["@/views/community/admin/active/member"], resolve),
      },
      {
        path: "/community/admin/:communityId/active/content",
        name: "communityAdminActiveContent",
        component: (resolve) =>
          require(["@/views/community/admin/active/content"], resolve),
      },
      {
        path: "/community/admin/:communityId/active/points",
        name: "communityAdminActivePoints",
        component: (resolve) =>
          require(["@/views/community/admin/active/points"], resolve),
      },
      {
        path: "/community/admin/:communityId/active/tools",
        name: "communityAdminActiveTools",
        component: (resolve) =>
          require(["@/views/community/admin/active/tools"], resolve),
      },
      {
        path: "/community/admin/:communityId/renewal/dashboard",
        name: "communityAdminRenewalDashboard",
        component: (resolve) =>
          require(["@/views/community/admin/renewal/dashboard"], resolve),
      },
      {
        path: "/community/admin/:communityId/renewal/coupon",
        name: "communityAdminRenewalCoupon",
        component: (resolve) =>
          require(["@/views/community/admin/renewal/coupon"], resolve),
      },
      {
        path: "/community/admin/:communityId/renewal/notice",
        name: "communityAdminRenewalNotice",
        component: (resolve) =>
          require(["@/views/community/admin/renewal/notice"], resolve),
      },
      {
        path: "/community/admin/:communityId/renewal/page",
        name: "communityAdminRenewalPage",
        component: (resolve) =>
          require(["@/views/community/admin/renewal/page"], resolve),
      },
      {
        path: "/community/admin/:communityId/renewal/discount",
        name: "communityAdminRenewalDiscount",
        component: (resolve) =>
          require(["@/views/community/admin/renewal/discount"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/coupon",
        name: "communityAdminOperateCoupon",
        component: (resolve) =>
          require(["@/views/community/admin/operate/coupon"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/notice",
        name: "communityAdminOperateNotice",
        component: (resolve) =>
          require(["@/views/community/admin/operate/notice"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/qrcode",
        name: "communityAdminOperateQrcode",
        component: (resolve) =>
          require(["@/views/community/admin/operate/qrcode"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/page",
        name: "communityAdminOperatePage",
        component: (resolve) =>
          require(["@/views/community/admin/operate/page"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/points",
        name: "communityAdminOperatePoints",
        component: (resolve) =>
          require(["@/views/community/admin/operate/points"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/inspiration",
        name: "communityAdminOperateInspiration",
        component: (resolve) =>
          require(["@/views/community/admin/operate/inspiration"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/setting",
        name: "communityAdminOperateSetting",
        component: (resolve) =>
          require(["@/views/community/admin/operate/setting"], resolve),
      },
      {
        path: "/community/admin/:communityId/operate/living",
        name: "communityAdminOperateLiving",
        component: (resolve) =>
          require(["@/views/community/admin/operate/living"], resolve),
      },
      {
        path: "/community/admin/:communityId/setting",
        name: "communityAdminSettingIndex",
        component: (resolve) =>
          require(["@/views/community/admin/setting/index"], resolve),
      },
    ],
  },
  {
    path: "/study",
    name: "study",
    component: (resolve) => require(["@/views/study/index"], resolve),
  },
  {
    path: "/plat",
    name: "platform",
    component: (resolve) => require(["@/views/platform/index"], resolve),
    children: [
      {
        path: "/plat/:shopId/shop/dashboard",
        name: "shopDashboard",
        component: (resolve) =>
          require(["@/views/platform/shop/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/shop/promotion/channel",
        name: "shopPromotionChannel",
        component: (resolve) =>
          require(["@/views/platform/shop/promotion/channel"], resolve),
      },
      {
        path: "/plat/:shopId/shop/promotion/channel/wxwork",
        name: "shopPromotionChannelWxwork",
        component: (resolve) =>
          require(["@/views/platform/shop/promotion/wxwork"], resolve),
      },
      {
        path: "/plat/:shopId/shop/promotion/channel/sphshop",
        name: "shopPromotionChannelSphshop",
        component: (resolve) =>
          require(["@/views/platform/shop/promotion/sphshop"], resolve),
      },
      {
        path: "/plat/:shopId/shop/promotion/channel/sphshop/edit",
        name: "shopPromotionChannelSphshopEdit",
        component: (resolve) =>
          require(["@/views/platform/shop/promotion/sphshopEdit"], resolve),
      },
      {
        path: "/plat/:shopId/shop/promotion/channel/wxmp",
        name: "shopPromotionChannelWxmp",
        component: (resolve) =>
          require(["@/views/platform/shop/promotion/wxmp"], resolve),
      },
      {
        path: "/plat/:shopId/shop/promotion/channel/wxma",
        name: "shopPromotionChannelWxma",
        component: (resolve) =>
          require(["@/views/platform/shop/promotion/wxma"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/page",
        name: "shopDecorationPage",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/page"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/page/edit",
        name: "shopDecorationPageEdt",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/pageEdit"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/nav",
        name: "shopDecorationNav",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/nav"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/nav/edit",
        name: "shopDecorationNavEdit",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/navEdit"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/style",
        name: "shopDecorationStyle",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/style"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/setting",
        name: "shopDecorationSetting",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/setting"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/search",
        name: "shopDecorationSearch",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/search"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/channel",
        name: "shopDecorationChannel",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/channel"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/promotion",
        name: "shopDecorationPromotion",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/promotion"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/start",
        name: "shopDecorationStart",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/start"], resolve),
      },
      {
        path: "/plat/:shopId/shop/decoration/qrcode",
        name: "shopDecorationQrcode",
        component: (resolve) =>
          require(["@/views/platform/shop/decoration/qrcode"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/dashboard",
        name: "shopFinanceDashboard",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/trade",
        name: "shopFinanceTrade",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/trade"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/needpay",
        name: "shopFinanceNeedpay",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/needpay"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/business",
        name: "shopFinanceBusiness",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/business"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/independent",
        name: "shopFinanceIndependent",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/independent"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/post",
        name: "shopFinancePost",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/post"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/payment",
        name: "shopFinancePayment",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/payment"], resolve),
      },
      {
        path: "/plat/:shopId/shop/finance/receipt",
        name: "shopFinanceReceipt",
        component: (resolve) =>
          require(["@/views/platform/shop/finance/receipt"], resolve),
      },
      {
        path: "/plat/:shopId/shop/source/async",
        name: "shopMsgAsync",
        component: (resolve) =>
          require(["@/views/platform/shop/source/async"], resolve),
      },
      {
        path: "/plat/:shopId/shop/source/manage",
        name: "shopMsgManage",
        component: (resolve) =>
          require(["@/views/platform/shop/source/manage"], resolve),
      },
      {
        path: "/plat/:shopId/shop/msg/access",
        name: "shopMsgAccess",
        component: (resolve) =>
          require(["@/views/platform/shop/msg/access"], resolve),
      },
      {
        path: "/plat/:shopId/shop/msg/template",
        name: "shopMsgTemplate",
        component: (resolve) =>
          require(["@/views/platform/shop/msg/template"], resolve),
      },
      {
        path: "/plat/:shopId/shop/msg/notice",
        name: "shopMsgNotice",
        component: (resolve) =>
          require(["@/views/platform/shop/msg/notice"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/org",
        name: "shopSettingOrg",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/org"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/module",
        name: "shopSettingModule",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/module"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/module/user/edit",
        name: "shopSettingModuleUserEdit",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/moduleUserEdit"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/module/role/edit",
        name: "shopSettingModuleRoleEdit",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/moduleRoleEdit"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/module/role/view",
        name: "shopSettingModuleRoleView",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/moduleRoleView"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/info",
        name: "shopSettingInfo",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/info"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/identify",
        name: "shopSettingIdentify",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/identify"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/app",
        name: "shopSettingApp",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/app"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/system",
        name: "shopSettingSystem",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/system"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/remote",
        name: "shopSettingRemote",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/remote"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/useridentify",
        name: "shopSettingUseridentify",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/useridentify"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/task",
        name: "shopSettingTask",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/task"], resolve),
      },
      {
        path: "/plat/:shopId/shop/setting/dev",
        name: "shopSettingDev",
        component: (resolve) =>
          require(["@/views/platform/shop/setting/dev"], resolve),
      },
      {
        path: "/plat/:shopId/shop/safe/customer",
        name: "shopSafeCustomer",
        component: (resolve) =>
          require(["@/views/platform/shop/safe/customer"], resolve),
      },
      {
        path: "/plat/:shopId/shop/safe/manage",
        name: "shopSafeManage",
        component: (resolve) =>
          require(["@/views/platform/shop/safe/manage"], resolve),
      },
      {
        path: "/plat/:shopId/shop/safe/blacklist",
        name: "shopSafeBlacklist",
        component: (resolve) =>
          require(["@/views/platform/shop/safe/blacklist"], resolve),
      },
      {
        path: "/plat/:shopId/shop/safe/pool",
        name: "shopSafePool",
        component: (resolve) =>
          require(["@/views/platform/shop/safe/pool"], resolve),
      },
      {
        path: "/plat/:shopId/shop/safe/market",
        name: "shopSafeMarket",
        component: (resolve) =>
          require(["@/views/platform/shop/safe/market"], resolve),
      },
      {
        path: "/plat/:shopId/shop/safe/content",
        name: "shopSafeContent",
        component: (resolve) =>
          require(["@/views/platform/shop/safe/content"], resolve),
      },
      {
        path: "/plat/:shopId/mall/order/all",
        name: "mallOrderAll",
        component: (resolve) =>
          require(["@/views/platform/mall/order/all"], resolve),
      },
      {
        path: "/plat/:shopId/mall/order/send",
        name: "mallOrderSend",
        component: (resolve) =>
          require(["@/views/platform/mall/order/send"], resolve),
      },
      {
        path: "/plat/:shopId/mall/order/package",
        name: "mallOrderPackage",
        component: (resolve) =>
          require(["@/views/platform/mall/order/package"], resolve),
      },
      {
        path: "/plat/:shopId/mall/order/citypost",
        name: "mallOrderCitypost",
        component: (resolve) =>
          require(["@/views/platform/mall/order/citypost"], resolve),
      },
      {
        path: "/plat/:shopId/mall/aftersale/refund",
        name: "mallAftersaleRefund",
        component: (resolve) =>
          require(["@/views/platform/mall/aftersale/refund"], resolve),
      },
      {
        path: "/plat/:shopId/mall/aftersale/exchange",
        name: "mallAftersaleExchange",
        component: (resolve) =>
          require(["@/views/platform/mall/aftersale/exchange"], resolve),
      },
      {
        path: "/plat/:shopId/mall/market/promotion",
        name: "mallMarketPromotion",
        component: (resolve) =>
          require(["@/views/platform/mall/market/promotion"], resolve),
      },
      {
        path: "/plat/:shopId/mall/market/manage",
        name: "mallMarketManage",
        component: (resolve) =>
          require(["@/views/platform/mall/market/manage"], resolve),
      },
      {
        path: "/plat/:shopId/mall/inventory/setting",
        name: "mallInventorySetting",
        component: (resolve) =>
          require(["@/views/platform/mall/inventory/setting"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/list",
        name: "mallGoodsList",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/list"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/list/edit",
        name: "mallGoodsListEdit",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/goodsEdit"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/group",
        name: "mallGoodsGroup",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/group"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/category",
        name: "mallGoodsCategory",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/category"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/brand",
        name: "mallGoodsBrand",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/brand"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/supplier",
        name: "mallGoodsSupplier",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/supplier"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/supplier/edit",
        name: "mallGoodsSupplierEdit",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/supplierEdit"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/tag",
        name: "mallGoodsTag",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/tag"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/param",
        name: "mallGoodsParam",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/param"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/comment",
        name: "mallGoodsComment",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/comment"], resolve),
      },
      {
        path: "/plat/:shopId/mall/goods/channeled",
        name: "mallGoodsChanneled",
        component: (resolve) =>
          require(["@/views/platform/mall/goods/channeled"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/online",
        name: "mallSettingOnline",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/online"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/goods",
        name: "mallSettingGoods",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/goods"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/trade",
        name: "mallSettingTrade",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/trade"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/send",
        name: "mallSettingSend",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/send"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/order",
        name: "mallSettingOrder",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/order"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/voucher",
        name: "mallSettingVoucher",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/voucher"], resolve),
      },
      {
        path: "/plat/:shopId/mall/setting/page",
        name: "mallSettingPage",
        component: (resolve) =>
          require(["@/views/platform/mall/setting/page"], resolve),
      },
      {
        path: "/plat/:shopId/course/live/list",
        name: "courseLiveList",
        component: (resolve) =>
          require(["@/views/platform/course/live/list"], resolve),
      },
      {
        path: "/plat/:shopId/course/live/setting",
        name: "courseLiveSetting",
        component: (resolve) =>
          require(["@/views/platform/course/live/setting"], resolve),
      },
      {
        path: "/plat/:shopId/course/live/download",
        name: "courseLiveDownload",
        component: (resolve) =>
          require(["@/views/platform/course/live/download"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/list",
        name: "courseCourseList",
        component: (resolve) =>
          require(["@/views/platform/course/course/list"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/list/edit",
        name: "courseCourseListEdit",
        component: (resolve) =>
          require(["@/views/platform/course/course/courseEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/column",
        name: "courseCourseColumn",
        component: (resolve) =>
          require(["@/views/platform/course/course/column"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/column/edit",
        name: "courseCourseColumnEdit",
        component: (resolve) =>
          require(["@/views/platform/course/course/columnEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/column/detail",
        name: "courseCourseColumnDetail",
        component: (resolve) =>
          require(["@/views/platform/course/course/columnDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/bigcolumn",
        name: "courseCourseBigcolumn",
        component: (resolve) =>
          require(["@/views/platform/course/course/bigcolumn"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/bigcolumn/edit",
        name: "courseCourseBigcolumnEdit",
        component: (resolve) =>
          require(["@/views/platform/course/course/bigcolumnEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/bigcolumn/detail",
        name: "courseCourseBigcolumnDetail",
        component: (resolve) =>
          require(["@/views/platform/course/course/bigcolumnDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/training",
        name: "courseCourseTraining",
        component: (resolve) =>
          require(["@/views/platform/course/course/training"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/training/edit",
        name: "courseCourseTrainingEdit",
        component: (resolve) =>
          require(["@/views/platform/course/course/trainingEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/vip",
        name: "courseCourseVip",
        component: (resolve) =>
          require(["@/views/platform/course/course/vip"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/vip/edit",
        name: "courseCourseVipEdit",
        component: (resolve) =>
          require(["@/views/platform/course/course/vipEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/course/:type/subscribe",
        name: "courseCourseSubscribe",
        component: (resolve) =>
          require(["@/views/platform/course/course/subscribe"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/video",
        name: "courseContentVideo",
        component: (resolve) =>
          require(["@/views/platform/course/content/video"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/video/edit",
        name: "courseContentVideoEdit",
        component: (resolve) =>
          require(["@/views/platform/course/content/videoEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/video/detail",
        name: "courseContentVideoDetail",
        component: (resolve) =>
          require(["@/views/platform/course/content/videoDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/audio",
        name: "courseContentAudio",
        component: (resolve) =>
          require(["@/views/platform/course/content/audio"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/audio/edit",
        name: "courseContentAudioEdit",
        component: (resolve) =>
          require(["@/views/platform/course/content/audioEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/audio/detail",
        name: "courseContentAudioDetail",
        component: (resolve) =>
          require(["@/views/platform/course/content/audioDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/picture",
        name: "courseContentPicture",
        component: (resolve) =>
          require(["@/views/platform/course/content/picture"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/document",
        name: "courseContentDocument",
        component: (resolve) =>
          require(["@/views/platform/course/content/document"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/rich",
        name: "courseContentRich",
        component: (resolve) =>
          require(["@/views/platform/course/content/rich"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/rich/edit",
        name: "courseContentRichEdit",
        component: (resolve) =>
          require(["@/views/platform/course/content/richEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/rich/detail",
        name: "courseContentRichDetail",
        component: (resolve) =>
          require(["@/views/platform/course/content/richDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/ebook",
        name: "courseContentEbook",
        component: (resolve) =>
          require(["@/views/platform/course/content/ebook"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/:type/subscribe",
        name: "courseContentSubscribe",
        component: (resolve) =>
          require(["@/views/platform/course/content/subscribe"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/audio/batch",
        name: "courseContentAudioBatchEdit",
        component: (resolve) =>
          require(["@/views/platform/course/content/audioBatchEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/content/video/batch",
        name: "courseContentVideoBatchEdit",
        component: (resolve) =>
          require(["@/views/platform/course/content/videoBatchEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/punch",
        name: "courseInteractionPunch",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/punch"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/punch/edit",
        name: "courseInteractionPunchEdit",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/punchEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/punch/detail",
        name: "courseInteractionPunchDetail",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/punchDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/punch/subscribe",
        name: "coursePunchSubscribe",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/punchSubscribe",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/exam",
        name: "courseInteractionExam",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/exam"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/practice",
        name: "courseInteractionPractice",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/practice"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/ecbook",
        name: "courseInteractionEcbook",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/ecbook"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/qabank",
        name: "courseInteractionQabank",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/qabank"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/qabank/editqa",
        name: "courseInteractionQabankEditQa",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/qabankQaEdit",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/qabank/editpaper",
        name: "courseInteractionQabankEditPaper",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/qabankPaperEdit",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/certificate",
        name: "courseInteractionCertificate",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/certificate"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/certificate/edit",
        name: "courseInteractionCertificateEdit",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/certificateEdit",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/detection",
        name: "courseInteractionDetection",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/detection"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/qapayment",
        name: "courseInteractionQapayment",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/qapayment"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/club",
        name: "courseInteractionClub",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/club"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/club/detail",
        name: "courseInteractionClubDetail",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/clubDetail"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/club/edit",
        name: "courseInteractionClubEdit",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/clubEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/club/dynamic/edit",
        name: "courseInteractionClubDynamicEdit",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/clubDynamicEdit",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/competition",
        name: "courseInteractionCompetition",
        component: (resolve) =>
          require(["@/views/platform/course/interaction/competition"], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/competition/detail",
        name: "courseInteractionCompetitionDetail",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/competitionDetail",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/interaction/competition/edit",
        name: "courseInteractionCompetitionEdit",
        component: (resolve) =>
          require([
            "@/views/platform/course/interaction/competitionEdit",
          ], resolve),
      },
      {
        path: "/plat/:shopId/course/edu/teacher",
        name: "courseEduTeacher",
        component: (resolve) =>
          require(["@/views/platform/course/edu/teacher"], resolve),
      },
      {
        path: "/plat/:shopId/course/edu/teacher/edit",
        name: "courseEduTeacherEdit",
        component: (resolve) =>
          require(["@/views/platform/course/edu/teacherEdit"], resolve),
      },
      {
        path: "/plat/:shopId/course/edu/comment",
        name: "courseEduComment",
        component: (resolve) =>
          require(["@/views/platform/course/edu/comment"], resolve),
      },
      {
        path: "/plat/:shopId/course/edu/student",
        name: "courseEduStudent",
        component: (resolve) =>
          require(["@/views/platform/course/edu/student"], resolve),
      },
      {
        path: "/plat/:shopId/course/channels/douyin",
        name: "courseChannelsDouyin",
        component: (resolve) =>
          require(["@/views/platform/course/channels/douyin"], resolve),
      },
      {
        path: "/plat/:shopId/course/channels/sph",
        name: "courseChannelsSph",
        component: (resolve) =>
          require(["@/views/platform/course/channels/sph"], resolve),
      },
      {
        path: "/plat/:shopId/course/channels/kuaishou",
        name: "courseChannelsKuaishou",
        component: (resolve) =>
          require(["@/views/platform/course/channels/kuaishou"], resolve),
      },
      {
        path: "/plat/:shopId/course/channels/xhs",
        name: "courseChannelsXhs",
        component: (resolve) =>
          require(["@/views/platform/course/channels/xhs"], resolve),
      },
      {
        path: "/plat/:shopId/course/channels/baidu",
        name: "courseChannelsBaidu",
        component: (resolve) =>
          require(["@/views/platform/course/channels/baidu"], resolve),
      },
      {
        path: "/plat/:shopId/course/channels/public",
        name: "courseChannelsPublic",
        component: (resolve) =>
          require(["@/views/platform/course/channels/public"], resolve),
      },
      {
        path: "/plat/:shopId/course/order/course",
        name: "courseOrderCourse",
        component: (resolve) =>
          require(["@/views/platform/course/order/course"], resolve),
      },
      {
        path: "/plat/:shopId/crm/customer/list",
        name: "crmCustomerList",
        component: (resolve) =>
          require(["@/views/platform/crm/customer/list"], resolve),
      },
      {
        path: "/plat/:shopId/crm/customer/group",
        name: "crmCustomerGroup",
        component: (resolve) =>
          require(["@/views/platform/crm/customer/group"], resolve),
      },
      {
        path: "/plat/:shopId/crm/customer/tag",
        name: "crmCustomerTag",
        component: (resolve) =>
          require(["@/views/platform/crm/customer/tag"], resolve),
      },
      {
        path: "/plat/:shopId/crm/customer/setting",
        name: "crmCustomerSetting",
        component: (resolve) =>
          require(["@/views/platform/crm/customer/setting"], resolve),
      },
      {
        path: "/plat/:shopId/crm/market/list",
        name: "crmMarketList",
        component: (resolve) =>
          require(["@/views/platform/crm/market/list"], resolve),
      },
      {
        path: "/plat/:shopId/crm/market/touch",
        name: "crmMarketTouch",
        component: (resolve) =>
          require(["@/views/platform/crm/market/touch"], resolve),
      },
      {
        path: "/plat/:shopId/crm/market/coupon",
        name: "crmMarketCoupon",
        component: (resolve) =>
          require(["@/views/platform/crm/market/coupon"], resolve),
      },
      {
        path: "/plat/:shopId/crm/member/member",
        name: "crmMemberMember",
        component: (resolve) =>
          require(["@/views/platform/crm/member/member"], resolve),
      },
      {
        path: "/plat/:shopId/crm/member/vip",
        name: "crmMemberVip",
        component: (resolve) =>
          require(["@/views/platform/crm/member/vip"], resolve),
      },
      {
        path: "/plat/:shopId/crm/member/card",
        name: "crmMemberCard",
        component: (resolve) =>
          require(["@/views/platform/crm/member/card"], resolve),
      },
      {
        path: "/plat/:shopId/crm/member/privilege",
        name: "crmMemberPrivilege",
        component: (resolve) =>
          require(["@/views/platform/crm/member/privilege"], resolve),
      },
      {
        path: "/plat/:shopId/crm/coupon/coupon",
        name: "crmCouponCoupon",
        component: (resolve) =>
          require(["@/views/platform/crm/coupon/coupon"], resolve),
      },
      {
        path: "/plat/:shopId/crm/coupon/code",
        name: "crmCouponCode",
        component: (resolve) =>
          require(["@/views/platform/crm/coupon/code"], resolve),
      },
      {
        path: "/plat/:shopId/crm/coupon/package",
        name: "crmCouponPackage",
        component: (resolve) =>
          require(["@/views/platform/crm/coupon/package"], resolve),
      },
      {
        path: "/plat/:shopId/crm/property/point",
        name: "crmPropertyPoint",
        component: (resolve) =>
          require(["@/views/platform/crm/property/point"], resolve),
      },
      {
        path: "/plat/:shopId/crm/property/store",
        name: "crmPropertyStore",
        component: (resolve) =>
          require(["@/views/platform/crm/property/store"], resolve),
      },
      {
        path: "/plat/:shopId/crm/analysis/customer",
        name: "crmAnalysisCustomer",
        component: (resolve) =>
          require(["@/views/platform/crm/analysis/customer"], resolve),
      },
      {
        path: "/plat/:shopId/crm/analysis/member",
        name: "crmAnalysisMember",
        component: (resolve) =>
          require(["@/views/platform/crm/analysis/member"], resolve),
      },
      {
        path: "/plat/:shopId/crm/analysis/property",
        name: "crmAnalysisProperty",
        component: (resolve) =>
          require(["@/views/platform/crm/analysis/property"], resolve),
      },
      {
        path: "/plat/:shopId/crm/analysis/market",
        name: "crmAnalysisMarket",
        component: (resolve) =>
          require(["@/views/platform/crm/analysis/market"], resolve),
      },
      {
        path: "/plat/:shopId/crm/setting/import",
        name: "crmSettingImport",
        component: (resolve) =>
          require(["@/views/platform/crm/setting/import"], resolve),
      },
      {
        path: "/plat/:shopId/guide/manage/list",
        name: "guideManageList",
        component: (resolve) =>
          require(["@/views/platform/guide/manage/list"], resolve),
      },
      {
        path: "/plat/:shopId/guide/manage/setting",
        name: "guideManageSetting",
        component: (resolve) =>
          require(["@/views/platform/guide/manage/setting"], resolve),
      },
      {
        path: "/plat/:shopId/guide/customer/tools",
        name: "guideCustomerTools",
        component: (resolve) =>
          require(["@/views/platform/guide/customer/tools"], resolve),
      },
      {
        path: "/plat/:shopId/guide/customer/clue",
        name: "guideCustomerClue",
        component: (resolve) =>
          require(["@/views/platform/guide/customer/clue"], resolve),
      },
      {
        path: "/plat/:shopId/guide/customer/list",
        name: "guideCustomerList",
        component: (resolve) =>
          require(["@/views/platform/guide/customer/list"], resolve),
      },
      {
        path: "/plat/:shopId/guide/follow/tools",
        name: "guideFollowTools",
        component: (resolve) =>
          require(["@/views/platform/guide/follow/tools"], resolve),
      },
      {
        path: "/plat/:shopId/guide/follow/record",
        name: "guideFollowRecord",
        component: (resolve) =>
          require(["@/views/platform/guide/follow/record"], resolve),
      },
      {
        path: "/plat/:shopId/guide/task/list",
        name: "guideTaskList",
        component: (resolve) =>
          require(["@/views/platform/guide/task/list"], resolve),
      },
      {
        path: "/plat/:shopId/guide/incentive/setting",
        name: "guideIncentiveSetting",
        component: (resolve) =>
          require(["@/views/platform/guide/incentive/setting"], resolve),
      },
      {
        path: "/plat/:shopId/guide/incentive/reward",
        name: "guideIncentiveReward",
        component: (resolve) =>
          require(["@/views/platform/guide/incentive/reward"], resolve),
      },
      {
        path: "/plat/:shopId/guide/incentive/report",
        name: "guideIncentiveReport",
        component: (resolve) =>
          require(["@/views/platform/guide/incentive/report"], resolve),
      },
      {
        path: "/plat/:shopId/work/dashboard",
        name: "workDashboard",
        component: (resolve) =>
          require(["@/views/platform/work/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/channel",
        name: "workIncreaseChannel",
        component: (resolve) =>
          require(["@/views/platform/work/increase/channel"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/channel/edit",
        name: "workIncreaseChannelEdit",
        component: (resolve) =>
          require(["@/views/platform/work/increase/channelEdit"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/lbs",
        name: "workIncreaseLbs",
        component: (resolve) =>
          require(["@/views/platform/work/increase/lbs"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/customer",
        name: "workIncreaseCustomer",
        component: (resolve) =>
          require(["@/views/platform/work/increase/customer"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/locked",
        name: "workIncreaseLocked",
        component: (resolve) =>
          require(["@/views/platform/work/increase/locked"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/aicall",
        name: "workIncreaseAicall",
        component: (resolve) =>
          require(["@/views/platform/work/increase/aicall"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/fission",
        name: "workIncreaseFission",
        component: (resolve) =>
          require(["@/views/platform/work/increase/fission"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/sphfission",
        name: "workIncreaseSphfission",
        component: (resolve) =>
          require(["@/views/platform/work/increase/sphfission"], resolve),
      },
      {
        path: "/plat/:shopId/work/increase/dbfission",
        name: "workIncreaseDbfission",
        component: (resolve) =>
          require(["@/views/platform/work/increase/dbfission"], resolve),
      },
      {
        path: "/plat/:shopId/work/operate/multiple",
        name: "workOperateMultiple",
        component: (resolve) =>
          require(["@/views/platform/work/operate/multiple"], resolve),
      },
      {
        path: "/plat/:shopId/work/operate/sop",
        name: "workOperateSop",
        component: (resolve) =>
          require(["@/views/platform/work/operate/sop"], resolve),
      },
      {
        path: "/plat/:shopId/work/operate/timeline",
        name: "workOperateTimeline",
        component: (resolve) =>
          require(["@/views/platform/work/operate/timeline"], resolve),
      },
      {
        path: "/plat/:shopId/work/operate/welcome",
        name: "workOperateWelcome",
        component: (resolve) =>
          require(["@/views/platform/work/operate/welcome"], resolve),
      },
      {
        path: "/plat/:shopId/work/operate/redbag",
        name: "workOperateRedbag",
        component: (resolve) =>
          require(["@/views/platform/work/operate/redbag"], resolve),
      },
      {
        path: "/plat/:shopId/work/operate/comline",
        name: "workOperateComline",
        component: (resolve) =>
          require(["@/views/platform/work/operate/comline"], resolve),
      },
      {
        path: "/plat/:shopId/work/customer/list",
        name: "workCustomerList",
        component: (resolve) =>
          require(["@/views/platform/work/customer/list"], resolve),
      },
      {
        path: "/plat/:shopId/work/customer/lost",
        name: "workCustomerLost",
        component: (resolve) =>
          require(["@/views/platform/work/customer/lost"], resolve),
      },
      {
        path: "/plat/:shopId/work/customer/stage",
        name: "workCustomerStage",
        component: (resolve) =>
          require(["@/views/platform/work/customer/stage"], resolve),
      },
      {
        path: "/plat/:shopId/work/customer/pool",
        name: "workCustomerPool",
        component: (resolve) =>
          require(["@/views/platform/work/customer/pool"], resolve),
      },
      {
        path: "/plat/:shopId/work/gincrease/group",
        name: "workGincreaseGroup",
        component: (resolve) =>
          require(["@/views/platform/work/gincrease/group"], resolve),
      },
      {
        path: "/plat/:shopId/work/gincrease/infinite",
        name: "workGincreaseInfinite",
        component: (resolve) =>
          require(["@/views/platform/work/gincrease/infinite"], resolve),
      },
      {
        path: "/plat/:shopId/work/gincrease/fission",
        name: "workGincreaseFission",
        component: (resolve) =>
          require(["@/views/platform/work/gincrease/fission"], resolve),
      },
      {
        path: "/plat/:shopId/work/gincrease/tag",
        name: "workGincreaseTag",
        component: (resolve) =>
          require(["@/views/platform/work/gincrease/tag"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/sop",
        name: "workServiceSop",
        component: (resolve) =>
          require(["@/views/platform/work/service/sop"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/clockin",
        name: "workServiceClockin",
        component: (resolve) =>
          require(["@/views/platform/work/service/clockin"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/customer",
        name: "workServiceCustomer",
        component: (resolve) =>
          require(["@/views/platform/work/service/customer"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/welcome",
        name: "workServiceWelcome",
        component: (resolve) =>
          require(["@/views/platform/work/service/welcome"], resolve),
      },
      {
        path: "/plat/:shopId/work/source/talk",
        name: "workSourceTalk",
        component: (resolve) =>
          require(["@/views/platform/work/source/talk"], resolve),
      },
      {
        path: "/plat/:shopId/work/source/radar",
        name: "workSourceRadar",
        component: (resolve) =>
          require(["@/views/platform/work/source/radar"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/code",
        name: "workServiceCode",
        component: (resolve) =>
          require(["@/views/platform/work/service/code"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/order",
        name: "workServiceOrder",
        component: (resolve) =>
          require(["@/views/platform/work/service/order"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/guide",
        name: "workServiceGuide",
        component: (resolve) =>
          require(["@/views/platform/work/service/guide"], resolve),
      },
      {
        path: "/plat/:shopId/work/service/channel",
        name: "workServiceChannel",
        component: (resolve) =>
          require(["@/views/platform/work/service/channel"], resolve),
      },
      {
        path: "/plat/:shopId/work/analysis/market",
        name: "workAnalysisMarket",
        component: (resolve) =>
          require(["@/views/platform/work/analysis/market"], resolve),
      },
      {
        path: "/plat/:shopId/work/analysis/customer",
        name: "workAnalysisCustomer",
        component: (resolve) =>
          require(["@/views/platform/work/analysis/customer"], resolve),
      },
      {
        path: "/plat/:shopId/work/analysis/group",
        name: "workAnalysisGroup",
        component: (resolve) =>
          require(["@/views/platform/work/analysis/group"], resolve),
      },
      {
        path: "/plat/:shopId/work/analysis/trade",
        name: "workAnalysisTrade",
        component: (resolve) =>
          require(["@/views/platform/work/analysis/trade"], resolve),
      },
      {
        path: "/plat/:shopId/work/analysis/stage",
        name: "workAnalysisStage",
        component: (resolve) =>
          require(["@/views/platform/work/analysis/stage"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/employee",
        name: "workConversationEmployee",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/employee"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/customer",
        name: "workConversationCustomer",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/customer"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/search",
        name: "workConversationSearch",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/search"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/wordremind",
        name: "workConversationWordremind",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/wordremind"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/words",
        name: "workConversationWords",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/words"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/analysis",
        name: "workConversationAnalysis",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/analysis"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/detail",
        name: "workConversationDetail",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/detail"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/keyword",
        name: "workConversationKeyword",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/keyword"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/group",
        name: "workConversationGroup",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/group"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/range",
        name: "workConversationRange",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/range"], resolve),
      },
      {
        path: "/plat/:shopId/work/conversation/timeout",
        name: "workConversationTimeout",
        component: (resolve) =>
          require(["@/views/platform/work/conversation/timeout"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/customer",
        name: "workWxserviceCustomer",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/customer"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/dashboard",
        name: "workWxserviceDashboard",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/clerk",
        name: "workWxserviceClerk",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/clerk"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/cgroup",
        name: "workWxserviceCgroup",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/cgroup"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/auto",
        name: "workWxserviceAuto",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/auto"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/tools",
        name: "workWxserviceTools",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/tools"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/setting",
        name: "workWxserviceSetting",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/setting"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/channel",
        name: "workWxserviceChannel",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/channel"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/account",
        name: "workWxserviceAccount",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/account"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/conversation",
        name: "workWxserviceConversation",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/conversation"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/msg",
        name: "workWxserviceMsg",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/msg"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/history",
        name: "workWxserviceHistory",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/history"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/analysis",
        name: "workWxserviceAnalysis",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/analysis"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/inspection",
        name: "workWxserviceInspection",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/inspection"], resolve),
      },
      {
        path: "/plat/:shopId/work/wxservice/kfanalysis",
        name: "workWxserviceKfanalysis",
        component: (resolve) =>
          require(["@/views/platform/work/wxservice/kfanalysis"], resolve),
      },
      {
        path: "/plat/:shopId/work/company/employee",
        name: "workCompanyEmployee",
        component: (resolve) =>
          require(["@/views/platform/work/company/employee"], resolve),
      },
      {
        path: "/plat/:shopId/work/company/diagnosis",
        name: "workCompanyDiagnosis",
        component: (resolve) =>
          require(["@/views/platform/work/company/diagnosis"], resolve),
      },
      {
        path: "/plat/:shopId/work/company/tools",
        name: "workCompanyTools",
        component: (resolve) =>
          require(["@/views/platform/work/company/tools"], resolve),
      },
      {
        path: "/plat/:shopId/work/company/remind",
        name: "workCompanyRemind",
        component: (resolve) =>
          require(["@/views/platform/work/company/remind"], resolve),
      },
      {
        path: "/plat/:shopId/work/company/robot",
        name: "workCompanyRobot",
        component: (resolve) =>
          require(["@/views/platform/work/company/robot"], resolve),
      },
      {
        path: "/plat/:shopId/cps/tweeter/list",
        name: "cpsTweeterList",
        component: (resolve) =>
          require(["@/views/platform/cps/tweeter/list"], resolve),
      },
      {
        path: "/plat/:shopId/cps/tweeter/audit",
        name: "cpsTweeterAudit",
        component: (resolve) =>
          require(["@/views/platform/cps/tweeter/audit"], resolve),
      },
      {
        path: "/plat/:shopId/cps/tweeter/setting",
        name: "cpsTweeterSetting",
        component: (resolve) =>
          require(["@/views/platform/cps/tweeter/setting"], resolve),
      },
      {
        path: "/plat/:shopId/cps/tweeter/tlist",
        name: "cpsTweeterTlist",
        component: (resolve) =>
          require(["@/views/platform/cps/tweeter/tlist"], resolve),
      },
      {
        path: "/plat/:shopId/cps/tweeter/taudit",
        name: "cpsTweeterTaudit",
        component: (resolve) =>
          require(["@/views/platform/cps/tweeter/taudit"], resolve),
      },
      {
        path: "/plat/:shopId/cps/tweeter/tsetting",
        name: "cpsTweeterTsetting",
        component: (resolve) =>
          require(["@/views/platform/cps/tweeter/tsetting"], resolve),
      },
      {
        path: "/plat/:shopId/cps/customer/tools",
        name: "cpsCustomerTools",
        component: (resolve) =>
          require(["@/views/platform/cps/customer/tools"], resolve),
      },
      {
        path: "/plat/:shopId/cps/customer/relation",
        name: "cpsCustomerRelation",
        component: (resolve) =>
          require(["@/views/platform/cps/customer/relation"], resolve),
      },
      {
        path: "/plat/:shopId/cps/customer/list",
        name: "cpsCustomerList",
        component: (resolve) =>
          require(["@/views/platform/cps/customer/list"], resolve),
      },
      {
        path: "/plat/:shopId/cps/spread/goods",
        name: "cpsSpreadGoods",
        component: (resolve) =>
          require(["@/views/platform/cps/spread/goods"], resolve),
      },
      {
        path: "/plat/:shopId/cps/spread/group",
        name: "cpsSpreadGroup",
        component: (resolve) =>
          require(["@/views/platform/cps/spread/group"], resolve),
      },
      {
        path: "/plat/:shopId/cps/spread/source",
        name: "cpsSpreadSource",
        component: (resolve) =>
          require(["@/views/platform/cps/spread/source"], resolve),
      },
      {
        path: "/plat/:shopId/cps/incentive/setting",
        name: "cpsIncentiveSetting",
        component: (resolve) =>
          require(["@/views/platform/cps/incentive/setting"], resolve),
      },
      {
        path: "/plat/:shopId/cps/incentive/report",
        name: "cpsIncentiveReport",
        component: (resolve) =>
          require(["@/views/platform/cps/incentive/report"], resolve),
      },
      {
        path: "/plat/:shopId/market/center",
        name: "marketCenter",
        component: (resolve) =>
          require(["@/views/platform/market/center"], resolve),
      },
      {
        path: "/plat/:shopId/market/manage/calendar",
        name: "marketManageCalendar",
        component: (resolve) =>
          require(["@/views/platform/market/manage/calendar"], resolve),
      },
      {
        path: "/plat/:shopId/market/manage/activity",
        name: "marketManageActivity",
        component: (resolve) =>
          require(["@/views/platform/market/manage/activity"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/dashboard/data",
        name: "analysisDashboardData",
        component: (resolve) =>
          require(["@/views/platform/analysis/dashboard/data"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/dashboard/realtime",
        name: "analysisDashboardRealtime",
        component: (resolve) =>
          require(["@/views/platform/analysis/dashboard/realtime"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/flow/dashboard",
        name: "analysisFlowDashboard",
        component: (resolve) =>
          require(["@/views/platform/analysis/flow/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/flow/page",
        name: "analysisFlowPage",
        component: (resolve) =>
          require(["@/views/platform/analysis/flow/page"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/flow/thermo",
        name: "analysisFlowThermo",
        component: (resolve) =>
          require(["@/views/platform/analysis/flow/thermo"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/flow/promotion",
        name: "analysisFlowPromotion",
        component: (resolve) =>
          require(["@/views/platform/analysis/flow/promotion"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/goods/dashboard",
        name: "analysisGoodsDashboard",
        component: (resolve) =>
          require(["@/views/platform/analysis/goods/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/goods/insight",
        name: "analysisGoodsInsight",
        component: (resolve) =>
          require(["@/views/platform/analysis/goods/insight"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/trade/dashboard",
        name: "analysisTradeDashboard",
        component: (resolve) =>
          require(["@/views/platform/analysis/trade/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/member/dashboard",
        name: "analysisMemberDashboard",
        component: (resolve) =>
          require(["@/views/platform/analysis/member/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/member/insight",
        name: "analysisMemberInsight",
        component: (resolve) =>
          require(["@/views/platform/analysis/member/insight"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/member/vip",
        name: "analysisMemberVip",
        component: (resolve) =>
          require(["@/views/platform/analysis/member/vip"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/member/point",
        name: "analysisMemberPoint",
        component: (resolve) =>
          require(["@/views/platform/analysis/member/point"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/member/store",
        name: "analysisMemberStore",
        component: (resolve) =>
          require(["@/views/platform/analysis/member/store"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/market/dashboard",
        name: "analysisMarketDashboard",
        component: (resolve) =>
          require(["@/views/platform/analysis/market/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/market/activity",
        name: "analysisMarketActivity",
        component: (resolve) =>
          require(["@/views/platform/analysis/market/activity"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/market/report",
        name: "analysisMarketReport",
        component: (resolve) =>
          require(["@/views/platform/analysis/market/report"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/service/dashboard",
        name: "analysisServiceDashboard",
        component: (resolve) =>
          require(["@/views/platform/analysis/service/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/channel/cps",
        name: "analysisChannelCps",
        component: (resolve) =>
          require(["@/views/platform/analysis/channel/cps"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/channel/supplier",
        name: "analysisChannelSupplier",
        component: (resolve) =>
          require(["@/views/platform/analysis/channel/supplier"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/channel/scan",
        name: "analysisChannelScan",
        component: (resolve) =>
          require(["@/views/platform/analysis/channel/scan"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/channel/writeoff",
        name: "analysisChannelWriteoff ",
        component: (resolve) =>
          require(["@/views/platform/analysis/channel/writeoff"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/channel/tweeter",
        name: "analysisChannelTweeter",
        component: (resolve) =>
          require(["@/views/platform/analysis/channel/tweeter"], resolve),
      },
      {
        path: "/plat/:shopId/analysis/channel/sph",
        name: "analysisChannelSph",
        component: (resolve) =>
          require(["@/views/platform/analysis/channel/sph"], resolve),
      },
      {
        path: "/plat/:shopId/service/dashboard",
        name: "serviceDashboard",
        component: (resolve) =>
          require(["@/views/platform/service/dashboard"], resolve),
      },
      {
        path: "/plat/:shopId/service/customer/list",
        name: "serviceCustomerList",
        component: (resolve) =>
          require(["@/views/platform/service/customer/list"], resolve),
      },
      {
        path: "/plat/:shopId/service/customer/tag",
        name: "serviceCustomerTag",
        component: (resolve) =>
          require(["@/views/platform/service/customer/tag"], resolve),
      },
      {
        path: "/plat/:shopId/service/customer/blacklist",
        name: "serviceCustomerBlacklist",
        component: (resolve) =>
          require(["@/views/platform/service/customer/blacklist"], resolve),
      },
      {
        path: "/plat/:shopId/service/customer/exclusive",
        name: "serviceCustomerExclusive",
        component: (resolve) =>
          require(["@/views/platform/service/customer/exclusive"], resolve),
      },
      {
        path: "/plat/:shopId/service/conversation/list",
        name: "serviceConversationList",
        component: (resolve) =>
          require(["@/views/platform/service/conversation/list"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/transfer",
        name: "serviceAnalysisTransfer",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/transfer"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/conversation",
        name: "serviceAnalysisConversation",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/conversation"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/customer",
        name: "serviceAnalysisCustomer",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/customer"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/msg",
        name: "serviceAnalysisMsg",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/msg"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/comment",
        name: "serviceAnalysisComment",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/comment"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/inspection",
        name: "serviceAnalysisInspection",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/inspection"], resolve),
      },
      {
        path: "/plat/:shopId/service/analysis/service",
        name: "serviceAnalysisService",
        component: (resolve) =>
          require(["@/views/platform/service/analysis/service"], resolve),
      },
      {
        path: "/plat/:shopId/service/servicer/list",
        name: "serviceServicerList",
        component: (resolve) =>
          require(["@/views/platform/service/servicer/list"], resolve),
      },
      {
        path: "/plat/:shopId/service/servicer/group",
        name: "serviceServicerGroup",
        component: (resolve) =>
          require(["@/views/platform/service/servicer/group"], resolve),
      },
      {
        path: "/plat/:shopId/service/servicer/card",
        name: "serviceServicerCard",
        component: (resolve) =>
          require(["@/views/platform/service/servicer/card"], resolve),
      },
      {
        path: "/plat/:shopId/service/servicer/coupon",
        name: "serviceServicerCoupon",
        component: (resolve) =>
          require(["@/views/platform/service/servicer/coupon"], resolve),
      },
      {
        path: "/plat/:shopId/service/auto/reply",
        name: "serviceAutoReply",
        component: (resolve) =>
          require(["@/views/platform/service/auto/reply"], resolve),
      },
      {
        path: "/plat/:shopId/service/auto/robot",
        name: "serviceAutoRobot",
        component: (resolve) =>
          require(["@/views/platform/service/auto/robot"], resolve),
      },
      {
        path: "/plat/:shopId/service/inspection/index",
        name: "serviceInspectionIndex",
        component: (resolve) =>
          require(["@/views/platform/service/inspection/index"], resolve),
      },
      {
        path: "/plat/:shopId/service/inspection/result",
        name: "serviceInspectionResult",
        component: (resolve) =>
          require(["@/views/platform/service/inspection/result"], resolve),
      },
      {
        path: "/plat/:shopId/service/inspection/setting",
        name: "serviceInspectionSetting",
        component: (resolve) =>
          require(["@/views/platform/service/inspection/setting"], resolve),
      },
      {
        path: "/plat/:shopId/service/system/rule",
        name: "serviceSystemRule",
        component: (resolve) =>
          require(["@/views/platform/service/system/rule"], resolve),
      },
      {
        path: "/plat/:shopId/service/system/channel",
        name: "serviceSystemChannel",
        component: (resolve) =>
          require(["@/views/platform/service/system/channel"], resolve),
      },
      {
        path: "/plat/:shopId/service/system/conversation",
        name: "serviceSystemConversation",
        component: (resolve) =>
          require(["@/views/platform/service/system/conversation"], resolve),
      },
      {
        path: "/plat/:shopId/service/system/other",
        name: "serviceSystemOther",
        component: (resolve) =>
          require(["@/views/platform/service/system/other"], resolve),
      },
      {
        path: "/plat/:shopId/service/personal/rule",
        name: "servicePersonalRule",
        component: (resolve) =>
          require(["@/views/platform/service/personal/rule"], resolve),
      },
      {
        path: "/plat/:shopId/service/personal/quick",
        name: "servicePersonalQuick",
        component: (resolve) =>
          require(["@/views/platform/service/personal/quick"], resolve),
      },
      {
        path: "/plat/:shopId/service/personal/remind",
        name: "servicePersonalRemind",
        component: (resolve) =>
          require(["@/views/platform/service/personal/remind"], resolve),
      },
      {
        path: "/plat/:shopId/service/personal/account",
        name: "servicePersonalAccount",
        component: (resolve) =>
          require(["@/views/platform/service/personal/account"], resolve),
      },
      {
        path: "/plat/:shopId/service/personal/notice",
        name: "servicePersonalNotice",
        component: (resolve) =>
          require(["@/views/platform/service/personal/notice"], resolve),
      },
      {
        path: "/plat/:shopId/content/douyin/live",
        name: "contentDouyinLive",
        component: (resolve) =>
          require(["@/views/platform/content/douyin/live"], resolve),
      },
      {
        path: "/plat/:shopId/content/douyin/tiny",
        name: "contentDouyinTiny",
        component: (resolve) =>
          require(["@/views/platform/content/douyin/tiny"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/record",
        name: "cardManageRecord",
        component: (resolve) =>
          require(["@/views/platform/card/manage/record"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/query",
        name: "cardManageQuery",
        component: (resolve) =>
          require(["@/views/platform/card/manage/query"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/bin",
        name: "cardManageBin",
        component: (resolve) =>
          require(["@/views/platform/card/manage/bin"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/type",
        name: "cardManageType",
        component: (resolve) =>
          require(["@/views/platform/card/manage/type"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/batch",
        name: "cardManageBatch",
        component: (resolve) =>
          require(["@/views/platform/card/manage/batch"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/make",
        name: "cardManageMake",
        component: (resolve) =>
          require(["@/views/platform/card/manage/make"], resolve),
      },
      {
        path: "/plat/:shopId/card/manage/receive",
        name: "cardManageReceive",
        component: (resolve) =>
          require(["@/views/platform/card/manage/receive"], resolve),
      },
      {
        path: "/plat/:shopId/card/customer/list",
        name: "cardCustomerList",
        component: (resolve) =>
          require(["@/views/platform/card/customer/list"], resolve),
      },
      {
        path: "/plat/:shopId/card/customer/tag",
        name: "cardCustomerTag",
        component: (resolve) =>
          require(["@/views/platform/card/customer/tag"], resolve),
      },
      {
        path: "/plat/:shopId/card/coupon/recharge",
        name: "cardCouponRecharge",
        component: (resolve) =>
          require(["@/views/platform/card/coupon/recharge"], resolve),
      },
      {
        path: "/plat/:shopId/card/coupon/batch",
        name: "cardCouponBatch",
        component: (resolve) =>
          require(["@/views/platform/card/coupon/batch"], resolve),
      },
      {
        path: "/plat/:shopId/card/order/take",
        name: "cardOrderTake",
        component: (resolve) =>
          require(["@/views/platform/card/order/take"], resolve),
      },
      {
        path: "/plat/:shopId/card/order/recharge",
        name: "cardOrderRecharge",
        component: (resolve) =>
          require(["@/views/platform/card/order/recharge"], resolve),
      },
      {
        path: "/plat/:shopId/card/order/refund",
        name: "cardOrderRefund",
        component: (resolve) =>
          require(["@/views/platform/card/order/refund"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/detail",
        name: "cardAnalysisDetail",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/detail"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/coupon",
        name: "cardAnalysisCoupon",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/coupon"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/customer",
        name: "cardAnalysisCustomer",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/customer"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/sale",
        name: "cardAnalysisSale",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/sale"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/recharge",
        name: "cardAnalysisRecharge",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/recharge"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/cost",
        name: "cardAnalysisCost",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/cost"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/expire",
        name: "cardAnalysisExpire",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/expire"], resolve),
      },
      {
        path: "/plat/:shopId/card/analysis/cardsale",
        name: "cardAnalysisCardsale",
        component: (resolve) =>
          require(["@/views/platform/card/analysis/cardsale"], resolve),
      },
      {
        path: "/plat/:shopId/shop/service/education",
        name: "shopServiceEducation",
        component: (resolve) =>
          require(["@/views/platform/shop/service/education"], resolve),
      },
      {
        path: "/plat/:shopId/house/msg/signature",
        name: "houseMsgSignature",
        component: (resolve) =>
          require(["@/views/platform/house/msg/signature"], resolve),
      },
      {
        path: "/plat/:shopId/house/msg/template",
        name: "houseMsgTemplate",
        component: (resolve) =>
          require(["@/views/platform/house/msg/template"], resolve),
      },
      {
        path: "/plat/:shopId/house/msg/groupsend",
        name: "houseMsgGroupsend",
        component: (resolve) =>
          require(["@/views/platform/house/msg/groupsend"], resolve),
      },
      {
        path: "/plat/:shopId/house/msg/analysis",
        name: "houseMsgAnalysis",
        component: (resolve) =>
          require(["@/views/platform/house/msg/analysis"], resolve),
      },
      {
        path: "/plat/:shopId/house/msg/setting",
        name: "houseMsgSetting",
        component: (resolve) =>
          require(["@/views/platform/house/msg/setting"], resolve),
      },
      {
        path: "/plat/:shopId/house/msg/notice",
        name: "houseMsgNotice",
        component: (resolve) =>
          require(["@/views/platform/house/msg/notice"], resolve),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

//路由前置守卫；用来设置元信息
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
