<template>
  <div class="normal-drawer" :style="{ background: background }">
    <div class="normal-drawer-head">
      <div class="head-title">
        <div v-if="title" class="title-text">{{ title }}</div>
        <div v-if="subtitle" class="subtitle-text">{{ subtitle }}</div>
        <slot name="title"></slot>
      </div>
      <div class="head-right">
        <div v-if="showClose" @click="closeDialog" class="head-close"></div>
      </div>
    </div>
    <div class="normal-drawer-body">
      <slot name="body"></slot>
    </div>
    <div v-if="showFoot" class="normal-drawer-foot">
      <div class="foot-left"></div>
      <div class="foot-right">
        <template v-if="!btns || btns.length === 0">
          <el-button @click="closeDialog" type="infor" size="small"
            >取消</el-button
          >
          <el-button @click="submitDialog" type="primary" size="small"
            >确定</el-button
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "transparent",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showFoot: {
      type: Boolean,
      default: true,
    },
    btns: { type: Array },
  },
  methods: {
    submitDialog() {
      this.$emit("submit");
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.normal-drawer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .normal-drawer-head {
    height: 64px;
    padding: 20px 24px;
    box-sizing: border-box;
    // background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .head-title {
      display: inline-flex;
      align-items: center;

      .title-text {
        margin-right: 12px;
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        color: #1e2226;
      }

      .subtitle-text {
        margin-right: 12px;
        line-height: 24px;
        font-size: 14px;
        color: #686767;
      }
    }

    .head-right {
      .head-close {
        width: 16px;
        height: 16px;
        background-size: 96px 16px;
        background-image: url(https://image.ydleague.com/benefit/web_icons/icon_normal_close.png);
        cursor: pointer;

        &:hover {
          background-position: -32px;
        }
      }
    }
  }

  .normal-drawer-body {
    width: 100%;
    height: 0;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }

  .normal-drawer-foot {
    padding: 12px 24px 20px;
    box-sizing: border-box;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .foot-left {
      display: inline-flex;
      align-items: center;
    }

    .foot-right {
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
