<template>
  <div class="share-layout">
    <el-form
      v-if="panelLabel === 'normal'"
      size="small"
      class="normal-form"
      label-width="85px"
      label-position="left"
    >
      <el-form-item label="分享方式">
        <div class="normal-form-row">
          <el-radio v-model="shareMode" :label="1">普通分享</el-radio>
          <el-radio v-model="shareMode" :label="2">引导用户绑定手机号</el-radio>
          <el-popover placement="right-start" trigger="hover">
            <div class="form-demo sfci" slot="reference">&#xe625;</div>
            <div class="demo-preview">
              <div class="demo-intro">
                适用于公域平台或线下售课，通过导入手机号给学员发放课程权益；发送链接给用户，用户绑定对应手机号后即可学习
              </div>
              <div class="demo-subtitle">用户端示例：</div>
              <el-image
                class="demo-preview-img"
                src="https://commonresource-1252524126.cdn.xiaoeknow.com/image/lbdrkywh0ila.png"
              ></el-image>
            </div>
          </el-popover>
        </div>
      </el-form-item>
      <el-form-item label="短链接">
        <div class="normal-form-row">
          <div class="link-input">
            <div class="link-text">https://mpnvo.xet.tech/s/2UpQxS</div>
            <el-button class="link-copy" type="primary">复制</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="原链接">
        <div class="normal-form-row">
          <div class="link-input">
            <div class="link-text">
              https://app2eyyxxjd8448.h5.xiaoeknow.com/p/course/video/v_63b84d32e4b02685a433b314
            </div>
            <el-button class="link-copy" type="primary">复制</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="二维码">
        <div class="normal-form-row">
          <el-image class="link-qrcode"></el-image>
        </div>
      </el-form-item>
      <el-form-item label="设置">
        <div class="normal-form-row">
          <el-button
            @click="panelLabel = 'sharetext'"
            type="text"
            size="medium"
          >
            分享语设置
          </el-button>
          <el-button
            @click="panelLabel = 'shareposter'"
            type="text"
            size="medium"
          >
            海报设置
          </el-button>
        </div>
      </el-form-item>
    </el-form>

    <div v-if="panelLabel === 'sharetext'" class="share-text-layout">
      <div class="share-text-container">
        <div class="share-preview-h5">
          <div class="preview-h5-title">样式预览</div>
          <div class="preview-h5-mobile">
            <div class="share-msg">
              <div class="msg-block">
                <div class="msg-block-title">
                  长链接转短链接 - 蜗牛卷 - 博客园
                </div>
                <div class="msg-block-desc">
                  直接返回短链接,并重置缓存时间;
                  2,没有,则在短链接后面添加自定义字符,重新hash,与此往复循环,直到布隆过
                </div>
                <div class="msg-block-cover">
                  <!-- <el-image class="cover-img"></el-image> -->
                </div>
              </div>
              <el-image
                class="preview-avatar"
                src="https://image.ydleague.com/benefit/web_icons/icon_dashboard_customer_avatar.jpg"
              ></el-image>
            </div>
          </div>
        </div>
        <el-form
          size="small"
          class="normal-form share-text-form"
          label-width="85px"
          label-position="left"
        >
          <el-form-item label="分享标题" required>
            <div class="normal-form-row">
              <el-input
                v-model="shareInfo.title"
                placeholder="请输入分享标题"
                maxlength="24"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分享描述" required>
            <div class="normal-form-row">
              <el-input
                v-model="shareInfo.title"
                placeholder="请输入分享标题"
                maxlength="31"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分享图片" required>
            <div
              v-if="shareInfo.imgUrl"
              @click="selectSource('选择图片', 'picture', 'cover')"
              class="normal-shareh5-upload"
            >
              <el-image
                fit="cover"
                class="shareh5-cover"
                :src="shareInfo.imgUrl"
              ></el-image>
            </div>
            <div
              v-else
              @click="selectSource('选择图片', 'picture', 'cover')"
              class="normal-shareh5-upload"
            >
              <i class="upload-icon el-icon-plus"></i>
              <span class="upload-text">上传图片</span>
            </div>
            <div class="normal-form-tips">
              <span class="tip-text">
                建议比例1:1，小于1M，适用于H5分享微信好友及微信朋友圈
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="share-text-foot">
        <el-button @click="closeShareText" type="infor" size="small">
          取消
        </el-button>
        <el-button @click="submitShareText" type="primary" size="small">
          确定
        </el-button>
      </div>
    </div>

    <div v-if="panelLabel === 'shareposter'" class="share-poster-layout">
      <div class="share-poster-container">
        <div class="share-poster-title">
          <span class="title-text">编辑海报</span>
          <el-popover placement="right-start" trigger="hover">
            <div class="form-demo sfci" slot="reference">&#xe625;</div>
            <div class="demo-preview">
              <div class="demo-intro">1、可自定义用户分享时海报的展示顺序</div>
              <div class="demo-intro">2、系统推荐海报无法编辑和删除</div>
              <div class="demo-intro">3、系统推荐海报可隐藏</div>
            </div>
          </el-popover>
        </div>
        <div class="share-poster-tips">
          最多可添加6张自定义海报，拖动改变海报顺序
        </div>
        <draggable class="poster-list" v-model="templates">
          <div class="poster-item add">
            <i class="poster-icon el-icon-plus"></i>
          </div>
          <template>
            <div v-for="(n, i) in templates" v-bind:key="i" class="poster-item">
              {{ n }}
            </div>
          </template>
        </draggable>
      </div>
      <div class="share-poster-foot">
        <el-button @click="closeShareText" type="infor" size="small">
          返回
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      shareMode: 1,
      panelLabel: "normal",
      shareInfo: {
        title: "",
        desc: "",
        imgUrl: "",
      },
      templates: [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6],
    };
  },
  methods: {
    closeShareText() {
      this.panelLabel = "normal";
    },
    submitShareText() {
      this.panelLabel = "normal";
    },
  },
};
</script>

<style lang="scss" scoped>
.share-layout {
  // padding: 0 24px;
  box-sizing: border-box;
  min-height: 640px;
}

.demo-preview-img {
  width: 228px;
  height: 494px;
}

.link-input {
  width: 540px;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: rgba(245, 247, 250, 0.48);

  .link-text {
    width: 0;
    height: 32px;
    flex-grow: 1;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    border: 1px solid #f2f2f2;
    border-right: 0;

    font-size: 14px;
    color: #888888;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .link-copy {
    border-radius: 0 4px 4px 0;
    border: 1px solid #0054d2;
    border-left: 0;
  }
}

.link-qrcode {
  width: 160px;
  height: 160px;
}

.share-text-layout {
  height: 640px;
  padding: 0 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .share-text-container {
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;

    .share-preview-h5 {
      width: 188px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .preview-h5-title {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
        color: #1e2226;
      }

      .preview-h5-mobile {
        width: 188px;
        height: 392px;
        background-size: 100% 100%;
        background-image: url(https://image.ydleague.com/benefit/web_icons/icon_preview_h5.png);
      }
    }

    .share-text-form {
      width: 0;
      flex-grow: 1;
      margin-left: 40px;
    }
  }

  .share-text-foot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.share-msg {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  .msg-block {
    position: absolute;
    top: 54px;
    left: 39px;

    width: 235px;
    padding: 16px;
    box-sizing: border-box;
    min-height: 117px;
    background-color: #ffffff;
    transform: scale(0.5);
    transform-origin: 0 0;

    .msg-block-title {
      margin-bottom: 6px;
      min-height: 30px;

      line-height: 22px;
      font-size: 16px;
      color: #1e2226;

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .msg-block-desc {
      margin-bottom: 6px;
      min-height: 30px;
      max-width: 155px;

      line-height: 16px;
      font-size: 12px;
      color: #8a9099;

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .msg-block-cover {
      position: absolute;
      right: 8px;
      bottom: 16px;
      width: 50px;
      height: 50px;
      background-color: #f7f8fa;
    }
  }

  .preview-avatar {
    position: absolute;
    top: 54px;
    right: 5px;

    width: 20px;
    height: 20px;
  }
}

.share-poster-layout {
  height: 640px;
  padding: 0 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .share-poster-container {
    width: 100%;
    height: 0;
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    .share-poster-title {
      display: flex;
      align-items: center;
      padding: 0 24px;
      box-sizing: border-box;

      .title-text {
        margin-right: 12px;
        font-weight: 600;
        font-size: 14px;
        color: #1e2226;
      }
    }

    .share-poster-tips {
      padding: 0 24px;
      box-sizing: border-box;
      margin-top: 10px;
      font-size: 14px;
      color: #8a9099;
    }

    .poster-list {
      width: 100%;
      height: 0;
      flex-grow: 1;
      overflow: auto;

      padding-left: 24px;
      box-sizing: border-box;

      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .poster-item {
        width: 90px;
        height: 160px;
        margin-right: 24px;
        margin-top: 12px;
        border-radius: 4px;
        background-color: #f7f8fa;

        &:nth-child(7n + 7) {
          margin-right: 0;
        }

        &.add {
          box-sizing: border-box;
          border: 1px dashed #dfe2e6;
          background-color: transparent;

          &:hover {
            border-color: var(--yd-primary);

            .poster-icon {
              color: var(--yd-primary);
            }
          }

          .poster-icon {
            line-height: 32px;
            font-size: 24px;
            color: #8a9099;
          }
        }
      }

      .poster-item.add {
        width: 90px;
        height: 160px;
        margin-right: 23px;
        margin-top: 12px;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px dashed #dfe2e6;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .share-poster-foot {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 0 24px;
    box-sizing: border-box;
  }
}
</style>
