<template>
  <div>
    <div class="system-safe">
      <div class="safe-container">
        <div class="safe-title">安全稳定企业服务</div>
        <div class="safe-desc">
          5年服务经验积累，99%安全保障，深度定制解决方案，成为您的专属团队
        </div>
        <div class="safe-btn">
          <span class="safe-btn-text">服务咨询</span>
          <i class="safe-btn-icon el-icon-right"></i>
        </div>
      </div>
    </div>
    <div class="system-service">
      <div class="service-container">
        <div class="service-item">
          <el-image
            class="service-item-icon"
            src="https://image.ydleague.com/benefit/web_icons/icon_website_service_24.svg"
          ></el-image>
          <div class="service-item-info">
            <div class="service-item-title">7*24服务支持</div>
            <div class="service-item-desc">多渠道服务支持</div>
          </div>
        </div>
        <div class="service-item">
          <el-image
            class="service-item-icon"
            src="https://image.ydleague.com/benefit/web_icons/icon_website_service_one.svg"
          ></el-image>
          <div class="service-item-info">
            <div class="service-item-title">一对一服务</div>
            <div class="service-item-desc">1V1大客户服务</div>
          </div>
        </div>
        <div class="service-item">
          <el-image
            class="service-item-icon"
            src="https://image.ydleague.com/benefit/web_icons/icon_website_service_full.svg"
          ></el-image>
          <div class="service-item-info">
            <div class="service-item-title">全周期专业服务</div>
            <div class="service-item-desc">渗透用户的深层需求</div>
          </div>
        </div>
        <div class="service-item">
          <el-image
            class="service-item-icon"
            src="https://image.ydleague.com/benefit/web_icons/icon_website_service_safe.svg"
          ></el-image>
          <div class="service-item-info">
            <div class="service-item-title">服务保障</div>
            <div class="service-item-desc">给予客户足够的尊重</div>
          </div>
        </div>
        <div class="service-item">
          <el-image
            class="service-item-icon"
            src="https://image.ydleague.com/benefit/web_icons/icon_website_service_advice.svg"
          ></el-image>
          <div class="service-item-info">
            <div class="service-item-title">建议反馈</div>
            <div class="service-item-desc">带领我们共同进步</div>
          </div>
        </div>
      </div>
    </div>
    <div class="website-foot" :class="{ dark: theme === 'dark' }">
      <div class="foot-container">
        <div class="foot-columns">
          <div class="column-item online-im">
            <div class="column-item-title">
              <i class="el-icon-service"></i>售前咨询热线
            </div>
            <div class="column-item-telephone">400-0808-160</div>
            <div class="column-item-imbtn">在线咨询</div>
          </div>
          <!-- <div class="column-item">
          <div class="column-item-title">产品与服务</div>
          <div class="column-item-links double-link">
            <div class="link-item">精选商城</div>
            <div class="link-item">电影票</div>
            <div class="link-item">蛋糕甜点</div>
            <div class="link-item">演出票</div>
            <div class="link-item">运动场馆</div>
            <div class="link-item">年节礼包</div>
            <div class="link-item">主题活动</div>
            <div class="link-item">职场课程</div>
            <div class="link-item">健康体检</div>
          </div>
        </div> -->
          <div class="column-item">
            <div class="column-item-title">解决方案</div>
            <div class="column-item-links">
              <div class="link-item">数字零售</div>
              <div class="link-item">本地生活</div>
              <div class="link-item">在线教育</div>
              <div class="link-item">私域增长</div>
              <div class="link-item">智能营销</div>
            </div>
          </div>
          <div class="column-item">
            <div class="column-item-title">椰豆云</div>
            <div class="column-item-links">
              <div class="link-item">开放接口</div>
              <div class="link-item">系统集成</div>
              <div class="link-item">定制开发</div>
            </div>
          </div>
          <!-- <div class="column-item">
          <div class="column-item-title">企业采购</div>
          <div class="column-item-links">
            <div class="link-item">卡券采购</div>
            <div class="link-item">办公用品</div>
            <div class="link-item">团建活动</div>
            <div class="link-item">文化建设</div>
            <div class="link-item">企业培训</div>
          </div>
        </div> -->
          <div class="column-item">
            <div class="column-item-title">生态合作</div>
            <div class="column-item-links">
              <div class="link-item">成为分销商</div>
              <div class="link-item">供应商入驻</div>
            </div>
          </div>
          <div class="column-item">
            <div class="column-item-title">关于我们</div>
            <div class="column-item-links">
              <div class="link-item">关于椰豆</div>
              <div class="link-item">联系我们</div>
            </div>
          </div>
          <div class="column-item">
            <div class="column-item-title">关注椰豆</div>
            <div class="column-item-tips">
              关注公众号、订阅小程序，了解更多资讯
            </div>
            <div class="column-item-qrcode">
              <el-image
                class="qrcode-item"
                src="https://image.ydleague.com/benefit/case/ydmp.jpg"
              ></el-image>
              <!-- <el-image
              class="qrcode-item"
              src="https://img01.yzcdn.cn/upload_files/2022/03/02/FvNF-I5ms-dhSMK4sQObPH0Btjsz.png"
            ></el-image> -->
            </div>
            <!-- <div class="column-item-official">
            <div class="official-item">
              <el-image
                class="official-item-ico"
                src="https://img01.yzcdn.cn/upload_files/2022/11/17/Fv1-eLsfYNpRyjvbkveutgBBOOL6.png"
              ></el-image>
              <div class="official-item-text">视频号</div>
            </div>
            <div class="official-item">
              <el-image
                class="official-item-ico"
                src="https://img01.yzcdn.cn/upload_files/2022/11/17/Fv1-eLsfYNpRyjvbkveutgBBOOL6.png"
              ></el-image>
              <div class="official-item-text">抖音</div>
            </div>
            <div class="official-item">
              <el-image
                class="official-item-ico"
                src="https://img01.yzcdn.cn/upload_files/2022/11/17/Fv1-eLsfYNpRyjvbkveutgBBOOL6.png"
              ></el-image>
              <div class="official-item-text">快手</div>
            </div>
          </div> -->
          </div>
        </div>

        <div class="foot-contacts">
          <div class="contact-item">总部咨询热线：010-62080812</div>
          <div class="contact-divider"></div>
          <div class="contact-item">邮箱：gongmeijing@ydleague.com</div>
          <div class="contact-divider"></div>
          <div class="contact-item">地址：北京市珠江摩尔国际大厦</div>
        </div>

        <div class="foot-bottom">
          <div class="foot-copyright">
            Copyright © 2013-2023 ydbenefit.com All Rights Reserved
          </div>
          <div class="foot-company">北京云尚创享科技有限公司</div>
          <div class="foot-icp">京ICP备2020043731号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    theme: {
      type: String,
      default: "light",
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.system-service {
  padding: 40px 0;

  .service-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .service-item {
      display: inline-flex;
      align-items: center;

      .service-item-icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }

      .service-item-info {
        .service-item-title {
          font-size: 16px;
          font-weight: 500;
          color: #1e2226;
          line-height: 20px;
        }

        .service-item-desc {
          font-size: 12px;
          font-weight: 400;
          color: #686767;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
  }
}

.system-safe {
  background-color: #eaf4fe;
  background-image: url(https://image.ydleague.com/benefit/web_icons/icon_website_foot_safe.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 180px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 36px;
  position: relative;

  .safe-container {
    width: 1200px;
    margin: auto;

    .safe-title {
      line-height: 24px;
      font-weight: 500;
      font-size: 24px;
      color: #1e2226;
    }

    .safe-desc {
      margin-top: 20px;
      line-height: 14px;
      font-weight: 400;
      font-size: 14px;
      color: #686767;
    }

    .safe-btn {
      width: 118px;
      height: 40px;
      padding: 0 20px;
      margin-top: 12px;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #1472ff;
      transition: all 0.3s;

      line-height: 24px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: var(--yd-primary);

        .safe-btn-icon {
          transform: translateX(3px);
        }

        .safe-btn-text {
          transform: translateX(-3px);
        }
      }

      .safe-btn-icon {
        font-size: 18px;
        transition: all 0.3s;
      }

      .safe-btn-text {
        transition: all 0.3s;
      }
    }
  }
}

.website-foot {
  width: 100%;
  background-color: #f0f0f0;

  &.dark {
    color: rgba($color: #ffffff, $alpha: 0.6);
    background-color: #17181b;

    .foot-container {
      .foot-columns {
        .column-item {
          .column-item-links {
            .link-item {
              &:hover {
                color: #ffffff;
              }
            }
          }
          .column-item-imbtn {
            width: 98px;
            line-height: 38px;
            text-align: center;
            box-sizing: border-box;
            border: 1px solid rgba($color: #e5e7eb, $alpha: 0.3);
            cursor: pointer;

            &:hover {
              background-color: rgba($color: #ffffff, $alpha: 0.3);
              border-color: rgba($color: #ffffff, $alpha: 0.3);
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .foot-container {
    width: 1190px;
    margin: 0 auto;

    .foot-columns {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 40px;

      .column-item {
        .column-item-title {
          line-height: 20px;
          font-weight: 500;
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .el-icon-service {
            margin-right: 6px;
            font-size: 18px;
          }
        }

        .column-item-tips {
          line-height: 14px;
          font-size: 12px;
          margin-bottom: 18px;
        }

        .column-item-qrcode {
          width: 156px;
          margin-bottom: 18px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          .qrcode-item {
            // width: 72px;
            // height: 72px;
            width: 100px;
            height: 100px;
          }
        }

        .column-item-official {
          display: flex;
          align-items: center;

          .official-item {
            width: 60px;
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            .official-item-ico {
              width: 20px;
              height: 20px;
              margin-bottom: 8px;
            }

            .official-item-text {
              line-height: 20px;
              font-size: 12px;
            }
          }
        }

        .column-item-links {
          width: 80px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          &.double-link {
            width: 160px;
          }

          .link-item {
            width: 80px;
            line-height: 14px;
            font-size: 13px;
            margin-bottom: 18px;
            cursor: pointer;

            &:hover {
              color: #f10215;
            }
          }
        }

        .column-item-telephone {
          font-size: 20px;
          margin-bottom: 20px;
        }

        .column-item-imbtn {
          width: 98px;
          line-height: 38px;
          text-align: center;
          box-sizing: border-box;
          border: 1px solid #e5e7eb;
          cursor: pointer;

          &:hover {
            background-color: #1472ff;
            border-color: #1472ff;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.foot-contacts {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .contact-item {
    margin-right: 10px;
    line-height: 20px;
    font-size: 12px;
  }

  .contact-divider {
    width: 1px;
    height: 10px;
    margin-right: 10px;
    background-color: #e5e7eb;
  }
}

.foot-bottom {
  display: flex;
  align-items: center;
  padding-bottom: 30px;

  .foot-copyright {
    line-height: 20px;
    font-size: 12px;
    margin-right: 10px;
  }

  .foot-company {
    line-height: 20px;
    font-size: 12px;
    margin-right: 10px;
  }

  .foot-icp {
    line-height: 20px;
    font-size: 12px;
    margin-right: 10px;
  }
}
</style>
