var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"website-head"},[_c('div',{staticClass:"head-container"},[_c('div',{staticClass:"head-item"},[_c('el-image',{staticClass:"head-logo",attrs:{"src":"https://bcdn.ddguanhuai.com/Public/Yuangong/asset/images/new_index_logo.png"}})],1),_c('div',{staticClass:"head-menus"},[_vm._m(0),_c('div',{staticClass:"head-menu-item"},[_c('el-popover',{attrs:{"placement":"bottom-start","trigger":"hover"}},[_c('div',{staticClass:"menu-item-text",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("产品服务")]),_c('div',{staticClass:"product-list"},_vm._l((_vm.products),function(n,i){return _c('div',{key:i,staticClass:"product-item",on:{"click":function($event){return _vm.openProduct(n)}}},[_vm._v(" "+_vm._s(n.name)),_c('i',{staticClass:"el-icon-arrow-right"})])}),0)])],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-menu-item"},[_c('div',{staticClass:"menu-item-text"},[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-menu-item"},[_c('div',{staticClass:"menu-item-text"},[_vm._v("椰豆云")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-menu-item"},[_c('div',{staticClass:"menu-item-text"},[_vm._v("企业采购")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-menu-item"},[_c('div',{staticClass:"menu-item-text"},[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-menu-item"},[_c('div',{staticClass:"menu-item-text"},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-item"},[_c('div',{staticClass:"head-call"},[_c('div',{staticClass:"call-item"},[_vm._v("在线咨询")]),_c('div',{staticClass:"call-item"},[_vm._v("采购电话：010-60731987")])])])
}]

export { render, staticRenderFns }