var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"system-service"},[_c('div',{staticClass:"service-container"},[_c('div',{staticClass:"service-item"},[_c('el-image',{staticClass:"service-item-icon",attrs:{"src":"https://image.ydleague.com/benefit/web_icons/icon_website_service_24.svg"}}),_vm._m(1)],1),_c('div',{staticClass:"service-item"},[_c('el-image',{staticClass:"service-item-icon",attrs:{"src":"https://image.ydleague.com/benefit/web_icons/icon_website_service_one.svg"}}),_vm._m(2)],1),_c('div',{staticClass:"service-item"},[_c('el-image',{staticClass:"service-item-icon",attrs:{"src":"https://image.ydleague.com/benefit/web_icons/icon_website_service_full.svg"}}),_vm._m(3)],1),_c('div',{staticClass:"service-item"},[_c('el-image',{staticClass:"service-item-icon",attrs:{"src":"https://image.ydleague.com/benefit/web_icons/icon_website_service_safe.svg"}}),_vm._m(4)],1),_c('div',{staticClass:"service-item"},[_c('el-image',{staticClass:"service-item-icon",attrs:{"src":"https://image.ydleague.com/benefit/web_icons/icon_website_service_advice.svg"}}),_vm._m(5)],1)])]),_c('div',{staticClass:"website-foot",class:{ dark: _vm.theme === 'dark' }},[_c('div',{staticClass:"foot-container"},[_c('div',{staticClass:"foot-columns"},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('div',{staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_vm._v("关注椰豆")]),_c('div',{staticClass:"column-item-tips"},[_vm._v(" 关注公众号、订阅小程序，了解更多资讯 ")]),_c('div',{staticClass:"column-item-qrcode"},[_c('el-image',{staticClass:"qrcode-item",attrs:{"src":"https://image.ydleague.com/benefit/case/ydmp.jpg"}})],1)])]),_vm._m(11),_vm._m(12)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-safe"},[_c('div',{staticClass:"safe-container"},[_c('div',{staticClass:"safe-title"},[_vm._v("安全稳定企业服务")]),_c('div',{staticClass:"safe-desc"},[_vm._v(" 5年服务经验积累，99%安全保障，深度定制解决方案，成为您的专属团队 ")]),_c('div',{staticClass:"safe-btn"},[_c('span',{staticClass:"safe-btn-text"},[_vm._v("服务咨询")]),_c('i',{staticClass:"safe-btn-icon el-icon-right"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item-info"},[_c('div',{staticClass:"service-item-title"},[_vm._v("7*24服务支持")]),_c('div',{staticClass:"service-item-desc"},[_vm._v("多渠道服务支持")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item-info"},[_c('div',{staticClass:"service-item-title"},[_vm._v("一对一服务")]),_c('div',{staticClass:"service-item-desc"},[_vm._v("1V1大客户服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item-info"},[_c('div',{staticClass:"service-item-title"},[_vm._v("全周期专业服务")]),_c('div',{staticClass:"service-item-desc"},[_vm._v("渗透用户的深层需求")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item-info"},[_c('div',{staticClass:"service-item-title"},[_vm._v("服务保障")]),_c('div',{staticClass:"service-item-desc"},[_vm._v("给予客户足够的尊重")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item-info"},[_c('div',{staticClass:"service-item-title"},[_vm._v("建议反馈")]),_c('div',{staticClass:"service-item-desc"},[_vm._v("带领我们共同进步")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-item online-im"},[_c('div',{staticClass:"column-item-title"},[_c('i',{staticClass:"el-icon-service"}),_vm._v("售前咨询热线 ")]),_c('div',{staticClass:"column-item-telephone"},[_vm._v("400-0808-160")]),_c('div',{staticClass:"column-item-imbtn"},[_vm._v("在线咨询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_vm._v("解决方案")]),_c('div',{staticClass:"column-item-links"},[_c('div',{staticClass:"link-item"},[_vm._v("数字零售")]),_c('div',{staticClass:"link-item"},[_vm._v("本地生活")]),_c('div',{staticClass:"link-item"},[_vm._v("在线教育")]),_c('div',{staticClass:"link-item"},[_vm._v("私域增长")]),_c('div',{staticClass:"link-item"},[_vm._v("智能营销")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_vm._v("椰豆云")]),_c('div',{staticClass:"column-item-links"},[_c('div',{staticClass:"link-item"},[_vm._v("开放接口")]),_c('div',{staticClass:"link-item"},[_vm._v("系统集成")]),_c('div',{staticClass:"link-item"},[_vm._v("定制开发")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_vm._v("生态合作")]),_c('div',{staticClass:"column-item-links"},[_c('div',{staticClass:"link-item"},[_vm._v("成为分销商")]),_c('div',{staticClass:"link-item"},[_vm._v("供应商入驻")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_vm._v("关于我们")]),_c('div',{staticClass:"column-item-links"},[_c('div',{staticClass:"link-item"},[_vm._v("关于椰豆")]),_c('div',{staticClass:"link-item"},[_vm._v("联系我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-contacts"},[_c('div',{staticClass:"contact-item"},[_vm._v("总部咨询热线：010-62080812")]),_c('div',{staticClass:"contact-divider"}),_c('div',{staticClass:"contact-item"},[_vm._v("邮箱：gongmeijing@ydleague.com")]),_c('div',{staticClass:"contact-divider"}),_c('div',{staticClass:"contact-item"},[_vm._v("地址：北京市珠江摩尔国际大厦")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-bottom"},[_c('div',{staticClass:"foot-copyright"},[_vm._v(" Copyright © 2013-2023 ydbenefit.com All Rights Reserved ")]),_c('div',{staticClass:"foot-company"},[_vm._v("北京云尚创享科技有限公司")]),_c('div',{staticClass:"foot-icp"},[_vm._v("京ICP备2020043731号")])])
}]

export { render, staticRenderFns }