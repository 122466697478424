<template>
  <div class="website-head">
    <div class="head-container">
      <div class="head-item">
        <el-image
          class="head-logo"
          src="https://bcdn.ddguanhuai.com/Public/Yuangong/asset/images/new_index_logo.png"
        ></el-image>
      </div>
      <div class="head-menus">
        <div class="head-menu-item">
          <div class="menu-item-text">首页</div>
        </div>
        <div class="head-menu-item">
          <el-popover placement="bottom-start" trigger="hover">
            <div class="menu-item-text" slot="reference">产品服务</div>
            <div class="product-list">
              <div
                @click="openProduct(n)"
                v-for="(n, i) in products"
                v-bind:key="i"
                class="product-item"
              >
                {{ n.name }}<i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="head-menu-item">
          <div class="menu-item-text">椰豆云</div>
        </div>
        <div class="head-menu-item">
          <div class="menu-item-text">企业采购</div>
        </div>
        <div class="head-menu-item">
          <div class="menu-item-text">关于我们</div>
        </div>
        <div class="head-menu-item">
          <div class="menu-item-text">联系我们</div>
        </div>
      </div>
      <div class="head-item">
        <!-- <div class="head-login">登录账号</div> -->
        <!-- <div class="head-reg">免费注册</div> -->
        <div class="head-call">
          <div class="call-item">在线咨询</div>
          <div class="call-item">采购电话：010-60731987</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        { route: "mall", name: "精选商城" },
        { route: "movie", name: "电影票" },
        { route: "cake", name: "蛋糕甜点" },
        { route: "perform", name: "演出票" },
        { route: "venue", name: "运动场馆" },
        { route: "flower", name: "鲜花速递" },
        { route: "gifts", name: "年节礼包" },
        { route: "activity", name: "主题活动" },
        { route: "course", name: "职场课程" },
        { route: "health", name: "健康体检" },
      ],
    };
  },
  created() {},
  methods: {
    openProduct(product) {
      this.$router.push({
        name: product.route,
        // query: { userId: user.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.website-head {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.05);

  display: flex;
  align-items: center;
  justify-content: center;

  .head-container {
    width: 1200px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .head-menus {
      width: 0;
      flex-grow: 1;
      margin-left: 50px;

      display: inline-flex;
      align-items: center;

      .head-menu-item {
        height: 70px;
        margin: 0 20px;
        color: #2b2b2b;
        cursor: pointer;

        display: inline-flex;
        align-items: center;

        &:hover {
          color: #1472ff;
        }

        .menu-item-text {
          letter-spacing: 1px;
          line-height: 32px;
          // font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .head-item {
      display: inline-flex;
      align-items: center;

      .head-logo {
        width: 154px;
        height: 41px;
      }

      .head-call {
        height: 46px;
        margin-left: 10px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .call-item {
          line-height: 20px;
          font-size: 12px;
          cursor: pointer;

          &:hover {
            color: #1472ff;
          }
        }
      }
    }
  }
}

.product-list {
  width: 260px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px 12px;
  box-sizing: border-box;

  .product-item {
    width: 48%;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    transition: all 0.2s;
    cursor: pointer;

    padding: 0 12px;
    margin-top: 12px;
    box-sizing: border-box;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      border-radius: 12px;
      background-color: #1472ff;
      color: #ffffff;

      i {
        color: #ffffff;
        border-color: #ffffff;
      }
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;
      margin-left: 12px;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      font-size: 12px;
      color: #8a9099;
      box-sizing: border-box;
    }
  }
}
</style>
