<template>
  <div class="website-head">
    <div class="head-container">
      <div class="head-item">
        <el-image
          class="head-logo"
          src="https://bcdn.ddguanhuai.com/Public/Yuangong/asset/images/new_index_logo.png"
        ></el-image>
      </div>
      <div class="head-slot">
        <slot></slot>
      </div>
      <div class="head-item">
        <!-- <div class="head-login">登录账号</div> -->
        <!-- <div class="head-reg">免费注册</div> -->
        <div class="head-call">
          <div class="call-item">在线咨询</div>
          <div class="call-item">采购电话：010-60731987</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.website-head {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.05);

  display: flex;
  align-items: center;
  justify-content: center;

  .head-container {
    width: 1200px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .head-slot {
      width: 0;
      flex-grow: 1;
      margin-left: 50px;

      display: inline-flex;
      align-items: center;
    }

    .head-item {
      display: inline-flex;
      align-items: center;

      .head-logo {
        width: 154px;
        height: 41px;
      }

      .head-call {
        height: 46px;
        margin-left: 10px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .call-item {
          line-height: 20px;
          font-size: 12px;
          cursor: pointer;

          &:hover {
            color: #1472ff;
          }
        }
      }
    }
  }
}

.product-list {
  width: 260px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px 12px;
  box-sizing: border-box;

  .product-item {
    width: 48%;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    transition: all 0.2s;
    cursor: pointer;

    padding: 0 12px;
    margin-top: 12px;
    box-sizing: border-box;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      border-radius: 12px;
      background-color: #1472ff;
      color: #ffffff;

      i {
        color: #ffffff;
        border-color: #ffffff;
      }
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;
      margin-left: 12px;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      font-size: 12px;
      color: #8a9099;
      box-sizing: border-box;
    }
  }
}
</style>
